// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";
// $bootstrap-icons-font-src: url("bootstrap-icons/font/fonts/bootstrap-icons.woff2")
//     format("woff2"),
//   url("bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
// $bootstrap-icons-font-dir: "bootstrap-icons/font/fonts";
// @import "bootstrap-icons/font/bootstrap-icons";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

$primary: #383838;
$secondary: #fdc400;

$white: #fff;

a:hover {
  color: $secondary;
}

.top-navbar {
  height: 3rem;
  border-bottom: $primary 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  color: $white;

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $white;
      text-decoration: none;
      &:hover {
        color: $secondary;
      }
    }
  }
}

.main-menu {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  * {
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }

  li {
    display: inline-block;
    list-style: outside none none;
    margin: 0 1rem;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 600;

    a {
      padding: 0.3em 0;
      color: $primary;
      position: relative;
      display: inline-block;
      letter-spacing: 1px;
      margin: 0;
      text-decoration: none;
    }
  }
}

.snip1226 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}
.snip1226 * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.snip1226 li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1rem;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
}
.snip1226 a {
  padding: 0.3em 0;
  color: $primary;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}
.snip1226 a:before,
.snip1226 a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.snip1226 a:before {
  bottom: 100%;
  display: block;
  height: 3px;
  width: 100%;
  content: "";
  background-color: $secondary;
}
.snip1226 a:after {
  padding: 0.3em 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: $secondary;
  white-space: nowrap;
}
.snip1226 li:hover a,
.snip1226 .current a {
  transform: translateY(100%);
}

.main-navbar {
  &.navbar {
    height: 5rem;
  }
  .navbar-brand {
    color: $secondary;
    font-size: 16px;
    font-weight: 600;

    @include media-breakpoint-up(sm) {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .nav-item {
    .nav-link {
      position: relative;
      display: inline-block;
      outline: none;
      overflow: hidden;
      padding: 4px 20px;
      font-weight: bold;
      color: #393939;
      text-decoration: none;

      &.active {
        color: $secondary;
      }

      &:hover {
        color: $secondary;
        span {
          position: relative;
          display: inline-block;
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
}

.total-info-row {
  background-image: url("../images/promo-03.jpg");

  .total-info {
    display: flex;
    padding: 28px 44px 32px 40px;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: skew(-22deg);
      -ms-transform: skew(-22deg);
      transform: skew(-22deg);
      background: rgb(230 223 223 / 10%);

      &::nth-child(odd) {
        background-color: rgba(35, 35, 35, 0.4);
      }

      &::nth-child(even) {
        background-color: rgba(77, 77, 77, 0.4);
      }
    }

    .icon {
      color: $secondary;
      font-size: 4rem;
    }
    .description {
      padding-left: 1.5rem;
      text-transform: uppercase;
      position: relative;
      font-size: 1.5rem;
      line-height: 1.1;
    }
    .numbers {
      color: $white;
    }
    .content {
      color: $white;
    }
  }
}

.section-wrapper {
  margin: 105px 0 0 0;
  padding-bottom: 2rem;
  .section-title {
    font-size: 2rem;
  }
}

.schedule-banner {
  background-image: url("../images/promo-03.jpg");
  padding: 20px 0;
  color: $white;
  span.highlight {
    color: $secondary;
  }
}

.feature-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  .icon-wrapper {
    position: relative;
    margin-bottom: 40px;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 10px;
      left: -10px;
      background: #f5f5f5;
      width: 84px;
      height: 84px;
      box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.1);
      transform: rotate(45deg);
      border-radius: 4px;
      background-clip: padding-box;
    }
    i {
      color: $secondary;
      font-size: 4rem;
    }
  }

  .feature-title {
    text-align: center;
  }
  .feature-content {
    text-align: center;
  }
}

.review-wrapper {
  padding: 3rem 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/parallax-img-01.jpg");
  background-size: cover;

  .section-title {
    color: $white;
    padding: 2rem 0;
  }
  .carousel-item {
    padding: 4rem;
    border: 2px solid $secondary;
    border-radius: 5px;
    color: $white;
  }
}

.package-wrapper {
  .package-box {
    text-decoration: none;
    display: block;
    text-align: center;
    margin: 29px auto 11px;
    padding: 0 0 26px;
    max-width: 434px;
    position: relative;
    border-radius: 5px;
    background-clip: padding-box;
    transition: all 0.2s linear;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);

    &.highlight {
      border: 2px solid $secondary;
      .package-content {
        .package-name {
          background-color: $secondary;
        }
      }
    }

    .package-content {
      padding: 1rem 0;

      .package-name {
        padding: 1rem;
        background-color: #565656;
        color: $white;

        &.highlight {
          background-color: $secondary;
        }

        .main-name {
          font-size: 1.5rem;
          font-size: 600;
        }
      }

      .price-details {
        padding-top: 1rem;
        .actual-price {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }
    }

    .time {
      font-size: 2rem;
    }
  }
}

.contact-wrapper {
  margin: 3rem 0;
  padding: 3rem 0;
  background: #333;

  .section-title {
    color: $white;
  }
}

.footer-wrapper {
  background-image: url("../images/promo-03.jpg");
  padding-top: 2rem;

  .footer-container {
    border-top: 5px solid $secondary;
    padding-top: 2rem;
    color: $white;

    .footer-title {
      color: $secondary;
      font-size: 1.25rem;
    }

    .footer-social-media {
      a {
        color: $white;
        font-size: 1.5rem;
        margin: 0.25rem;
      }

      a:hover {
        color: $secondary;
      }
    }
  }
}
